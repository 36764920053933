<template>
  <div class="text-center loader">
    <div style="position: relative; top: 40%; color:black !important">
      <div
        class="la-line-scale la-2x icon-wrapper">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loading1',
};
</script>

<style lang ="scss" src="./loading1.scss"></style>